export const transformCsvData = data => {
    const result = [];
    let lastDateInHistory = null;

    data.forEach(row => {
        let lastDateInHistoryKw = null;
        const { keyword, url } = row;
        const dateHeaders = row.DateHeaders; // Hlavičky s datumy
        const dates = row.Dates; // Hodnoty ranků

        if (!keyword) return; // Pokud není klíčové slovo, přeskočíme tento řádek

        let existingKeyword = result.find(item => item.keyword === keyword);

        if (!existingKeyword) {
            const lastRank = dates[dates.length - 1];
            existingKeyword = {
                keyword,
                history: [],
                source: 'se-ranking',
                last: { rank: lastRank === '-' && lastRank ? null : parseInt(lastRank, 10) },
            };
            result.push(existingKeyword);
        }

        dates.forEach((rank, index) => {
            const date = dateHeaders[index];
            if (!date) return;

            // save the oldest date in history
            if (!lastDateInHistory || date < lastDateInHistory) {
                lastDateInHistory = date;
            }

            if (!lastDateInHistoryKw || date < lastDateInHistoryKw) {
                lastDateInHistoryKw = date;
            }

            if (rank && rank !== '-' && rank !== '0' && rank !== 'No data' && rank !== '') {
                const dateEntry = {
                    [date]: {
                        main: [{ r: parseInt(rank, 10), u: url }],
                    },
                };
                existingKeyword.history.push(dateEntry);
                existingKeyword.lastDateInHistoryKw = lastDateInHistoryKw;
            }
        });
    });

    return { result, lastDateInHistory };
};

export const convertKwToObj = (kw: string, source: string) => ({ keyword: kw, history: [], source });
