const ActionTypes = {
    // ========================================================================
    // DATA ACTIONS
    // ========================================================================

    // Redux persist load completed
    DATA_REHYDRATATION_COMPLETE: 'DATA_REHYDRATATION_COMPLETE',

    // Location data
    DATA_LOCATIONS_ERROR: 'DATA_LOCATIONS_ERROR',
    DATA_LOCATIONS_FETCHING: 'DATA_LOCATIONS_FETCHING',
    DATA_LOCATIONS_RECEIVED: 'DATA_LOCATIONS_RECEIVED',
    DATA_LOCATIONS_REQUESTED: 'DATA_LOCATIONS_REQUESTED',

    // Lists data
    DATA_LISTS_ERROR: 'DATA_LISTS_ERROR',
    DATA_LISTS_FETCHING: 'DATA_LISTS_FETCHING',
    DATA_LISTS_KEYWORDS_ERROR: 'DATA_LISTS_KEYWORDS_ERROR',
    DATA_LISTS_KEYWORDS_FETCHING: 'DATA_LISTS_KEYWORDS_FETCHING',
    DATA_LISTS_KEYWORDS_RECEIVED: 'DATA_LISTS_KEYWORDS_RECEIVED',
    DATA_LISTS_RECEIVED: 'DATA_LISTS_RECEIVED',
    DATA_LISTS_REQUESTED: 'DATA_LISTS_REQUESTED',
    DATA_LISTS_SKIPPED: 'DATA_LISTS_SKIPPED',
    DATA_KWFINDER_LIST_URL_IMPORT_REQUESTED: 'DATA_KWFINDER_LIST_URL_IMPORT_REQUESTED',
    DATA_KWFINDER_LIST_URL_IMPORT_ERROR: 'DATA_KWFINDER_LIST_URL_IMPORT_ERROR',

    // Google Search Console data
    DATA_GSC_KEYWORDS_ERROR: 'DATA_GSC_KEYWORDS_ERROR',
    DATA_GSC_KEYWORDS_FETCHING: 'DATA_GSC_KEYWORDS_FETCHING',
    DATA_GSC_KEYWORDS_RECEIVED: 'DATA_GSC_KEYWORDS_RECEIVED',
    DATA_GSC_KEYWORDS_REQUESTED: 'DATA_GSC_KEYWORDS_REQUESTED',
    DATA_GSC_KEYWORDS_SKIPPED: 'DATA_GSC_KEYWORDS_SKIPPED',

    // Tracking detail data
    DATA_TRACKING_ADD_KEYWORDS_ERROR: 'DATA_TRACKING_ADD_KEYWORDS_ERROR',
    DATA_TRACKING_ADD_KEYWORDS_FETCHING: 'DATA_TRACKING_ADD_KEYWORDS_FETCHING',
    DATA_TRACKING_ADD_KEYWORDS_RECEIVED: 'DATA_TRACKING_ADD_KEYWORDS_RECEIVED',
    DATA_TRACKING_ADD_KEYWORDS_REQUESTED: 'DATA_TRACKING_ADD_KEYWORDS_REQUESTED',
    DATA_TRACKING_ANNOTATIONS_CREATE_ERROR: 'DATA_TRACKING_ANNOTATIONS_CREATE_ERROR',
    DATA_TRACKING_ANNOTATIONS_CREATE_FETCHING: 'DATA_TRACKING_ANNOTATIONS_CREATE_FETCHING',
    DATA_TRACKING_ANNOTATIONS_CREATE_RECEIVED: 'DATA_TRACKING_ANNOTATIONS_CREATE_RECEIVED',
    DATA_TRACKING_ANNOTATIONS_CREATE_REQUESTED: 'DATA_TRACKING_ANNOTATIONS_CREATE_REQUESTED',
    DATA_TRACKING_ANNOTATIONS_DELETE_ERROR: 'DATA_TRACKING_ANNOTATIONS_DELETE_ERROR',
    DATA_TRACKING_ANNOTATIONS_DELETE_RECEIVED: 'DATA_TRACKING_ANNOTATIONS_DELETE_RECEIVED',
    DATA_TRACKING_ANNOTATIONS_DELETE_REQUESTED: 'DATA_TRACKING_ANNOTATIONS_DELETE_REQUESTED',
    DATA_TRACKING_ANNOTATIONS_UPDATE_ERROR: 'DATA_TRACKING_ANNOTATIONS_UPDATE_ERROR',
    DATA_TRACKING_ANNOTATIONS_UPDATE_RECEIVED: 'DATA_TRACKING_ANNOTATIONS_UPDATE_RECEIVED',
    DATA_TRACKING_ANNOTATIONS_UPDATE_REQUESTED: 'DATA_TRACKING_ANNOTATIONS_UPDATE_REQUESTED',
    DATA_TRACKING_DETAIL_ERROR: 'DATA_TRACKING_DETAIL_ERROR',
    DATA_TRACKING_DETAIL_FETCHING: 'DATA_TRACKING_DETAIL_FETCHING',
    DATA_TRACKING_DETAIL_RECEIVED: 'DATA_TRACKING_DETAIL_RECEIVED',
    DATA_TRACKING_PANEL_RECEIVED: 'DATA_TRACKING_PANEL_RECEIVED',

    DATA_TRACKING_STATS_DETAIL_RECEIVED: 'DATA_TRACKING_STATS_DETAIL_RECEIVED',
    DATA_TRACKING_ALL_STATS_DETAIL_RECEIVED: 'DATA_TRACKING_ALL_STATS_DETAIL_RECEIVED',
    DATA_TRACKING_SELECTED_STATS_DETAIL_RECEIVED: 'DATA_TRACKING_SELECTED_STATS_DETAIL_RECEIVED',
    DATA_TRACKING_STATS_DETAIL_FETCHING: 'DATA_TRACKING_STATS_DETAIL_FETCHING',
    DATA_TRACKING_STATS_DETAIL_REQUESTED: 'DATA_TRACKING_STATS_DETAIL_REQUESTED',
    DATA_TRACKING_TIMEFRAME_DATA_DETAIL_FETCHING: 'DATA_TRACKING_TIMEFRAME_DATA_DETAIL_FETCHING',

    DATA_TRACKING_DETAIL_RELOAD_REQUESTED: 'DATA_TRACKING_DETAIL_RELOAD_REQUESTED',
    DATA_TRACKING_DETAIL_REQUESTED: 'DATA_TRACKING_DETAIL_REQUESTED',
    DATA_TRACKING_KEYWORDS_DELETE_ERROR: 'DATA_TRACKING_KEYWORDS_DELETE_ERROR',
    DATA_TRACKING_KEYWORDS_DELETE_FETCHING: 'DATA_TRACKING_KEYWORDS_DELETE_FETCHING',
    DATA_TRACKING_KEYWORDS_DELETE_RECEIVED: 'DATA_TRACKING_KEYWORDS_DELETE_RECEIVED',
    DATA_TRACKING_KEYWORDS_DELETE_REQUESTED: 'DATA_TRACKING_KEYWORDS_DELETE_REQUESTED',
    DATA_TRACKING_KEYWORDS_DELETE_SELECTED_CANCELLED: 'DATA_TRACKING_KEYWORDS_DELETE_SELECTED_CANCELLED',
    DATA_TRACKING_KEYWORDS_DELETE_SELECTED_ERROR: 'DATA_TRACKING_KEYWORDS_DELETE_SELECTED_ERROR',
    DATA_TRACKING_KEYWORDS_DELETE_SELECTED_FETCHING: 'DATA_TRACKING_KEYWORDS_DELETE_SELECTED_FETCHING',
    DATA_TRACKING_KEYWORDS_DELETE_SELECTED_RECEIVED: 'DATA_TRACKING_KEYWORDS_DELETE_SELECTED_RECEIVED',
    DATA_TRACKING_KEYWORDS_DELETE_SELECTED_REQUESTED: 'DATA_TRACKING_KEYWORDS_DELETE_SELECTED_REQUESTED',
    DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_CANCELLED: 'DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_CANCELLED',
    DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_ERROR: 'DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_ERROR',
    DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_FETCHING: 'DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_FETCHING',
    DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_RECEIVED: 'DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_RECEIVED',
    DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_REQUESTED: 'DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_REQUESTED',
    DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_ERROR: 'DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_ERROR',
    DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_FETCHING: 'DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_FETCHING',
    DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_RECEIVED: 'DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_RECEIVED',
    DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_REQUESTED: 'DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_REQUESTED',
    DATA_TRACKING_KEYWORD_DETAIL_ERROR: 'DATA_TRACKING_KEYWORD_DETAIL_ERROR',
    DATA_TRACKING_KEYWORD_DETAIL_FETCHING: 'DATA_TRACKING_KEYWORD_DETAIL_FETCHING',
    DATA_TRACKING_KEYWORD_DETAIL_RECEIVED: 'DATA_TRACKING_KEYWORD_DETAIL_RECEIVED',
    DATA_TRACKING_KEYWORD_DETAIL_REQUESTED: 'DATA_TRACKING_KEYWORD_DETAIL_REQUESTED',
    DATA_TRACKING_KEYWORD_DETAIL_RESET: 'DATA_TRACKING_KEYWORD_DETAIL_RESET',
    DATA_TRACKING_TAGS_ASSIGN_ERROR: 'DATA_TRACKING_TAGS_ASSIGN_ERROR',
    DATA_TRACKING_TAGS_ASSIGN_RECEIVED: 'DATA_TRACKING_TAGS_ASSIGN_RECEIVED',
    DATA_TRACKING_TAGS_ASSIGN_REQUESTED: 'DATA_TRACKING_TAGS_ASSIGN_REQUESTED',
    DATA_TRACKING_TAGS_BULK_ASSIGN_ERROR: 'DATA_TRACKING_TAGS_BULK_ASSIGN_ERROR',
    DATA_TRACKING_TAGS_BULK_ASSIGN_RECEIVED: 'DATA_TRACKING_TAGS_BULK_ASSIGN_RECEIVED',
    DATA_TRACKING_TAGS_BULK_ASSIGN_REQUESTED: 'DATA_TRACKING_TAGS_BULK_ASSIGN_REQUESTED',
    DATA_TRACKING_TAGS_BULK_UNASSIGN_ERROR: 'DATA_TRACKING_TAGS_BULK_UNASSIGN_ERROR',
    DATA_TRACKING_TAGS_BULK_UNASSIGN_RECEIVED: 'DATA_TRACKING_TAGS_BULK_UNASSIGN_RECEIVED',
    DATA_TRACKING_TAGS_BULK_UNASSIGN_REQUESTED: 'DATA_TRACKING_TAGS_BULK_UNASSIGN_REQUESTED',
    DATA_TRACKING_TAGS_CREATE_ERROR: 'DATA_TRACKING_TAGS_CREATE_ERROR',
    DATA_TRACKING_TAGS_CREATE_RECEIVED: 'DATA_TRACKING_TAGS_CREATE_RECEIVED',
    DATA_TRACKING_TAGS_CREATE_REQUESTED: 'DATA_TRACKING_TAGS_CREATE_REQUESTED',
    DATA_TRACKING_TAGS_DELETE_ERROR: 'DATA_TRACKING_TAGS_DELETE_ERROR',
    DATA_TRACKING_TAGS_DELETE_RECEIVED: 'DATA_TRACKING_TAGS_DELETE_RECEIVED',
    DATA_TRACKING_TAGS_DELETE_REQUESTED: 'DATA_TRACKING_TAGS_DELETE_REQUESTED',
    DATA_TRACKING_TAGS_ERROR: 'DATA_TRACKING_TAGS_ERROR',
    DATA_TRACKING_TAGS_FETCHING: 'DATA_TRACKING_TAGS_FETCHING',
    DATA_TRACKING_TAGS_RECEIVED: 'DATA_TRACKING_TAGS_RECEIVED',
    DATA_TRACKING_TAGS_REQUESTED: 'DATA_TRACKING_TAGS_REQUESTED',
    DATA_TRACKING_TAGS_UNASSIGN_ERROR: 'DATA_TRACKING_TAGS_UNASSIGN_ERROR',
    DATA_TRACKING_TAGS_UNASSIGN_FETCHING: 'DATA_TRACKING_TAGS_UNASSIGN_FETCHING',
    DATA_TRACKING_TAGS_UNASSIGN_RECEIVED: 'DATA_TRACKING_TAGS_UNASSIGN_RECEIVED',
    DATA_TRACKING_TAGS_UNASSIGN_REQUESTED: 'DATA_TRACKING_TAGS_UNASSIGN_REQUESTED',
    DATA_TRACKING_TAGS_UPDATE_COLOR_ERROR: 'DATA_TRACKING_TAGS_UPDATE_COLOR_ERROR',
    DATA_TRACKING_TAGS_UPDATE_COLOR_RECEIVED: 'DATA_TRACKING_TAGS_UPDATE_COLOR_RECEIVED',
    DATA_TRACKING_TAGS_UPDATE_COLOR_REQUESTED: 'DATA_TRACKING_TAGS_UPDATE_COLOR_REQUESTED',
    DATA_TRACKING_TAGS_UPDATE_NAME_ERROR: 'DATA_TRACKING_TAGS_UPDATE_NAME_ERROR',
    DATA_TRACKING_TAGS_UPDATE_NAME_RECEIVED: 'DATA_TRACKING_TAGS_UPDATE_NAME_RECEIVED',
    DATA_TRACKING_TAGS_UPDATE_NAME_REQUESTED: 'DATA_TRACKING_TAGS_UPDATE_NAME_REQUESTED',
    DATA_TRACKING_DOMAIN_UPDATE_REQUESTED: 'DATA_TRACKING_DOMAIN_UPDATE_REQUESTED',
    DATA_TRACKING_UPDATE_DOMAIN_RECEIVED: 'DATA_TRACKING_UPDATE_DOMAIN_RECEIVED',
    DATA_TRACKING_UPDATE_DOMAIN_ERROR: 'DATA_TRACKING_UPDATE_DOMAIN_ERROR',
    DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE: 'DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE',
    DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT: 'DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT',
    DATA_TRACKING_SHARE_TOKEN_UPDATE_REQUESTED: 'DATA_TRACKING_SHARE_TOKEN_UPDATE_REQUESTED',
    DATA_TRACKING_SHARE_TOKEN_UPDATE_RECEIVED: 'DATA_TRACKING_SHARE_TOKEN_UPDATE_RECEIVED',
    DATA_TRACKING_SHARE_TOKEN_UPDATE_ERROR: 'DATA_TRACKING_SHARE_TOKEN_UPDATE_ERROR',

    // New tracking
    DATA_NEW_TRACKING_CLONE_REQUESTED: 'DATA_NEW_TRACKING_CLONE_REQUESTED',
    DATA_NEW_TRACKING_ERROR: 'DATA_NEW_TRACKING_ERROR',
    DATA_NEW_TRACKING_FETCHING: 'DATA_NEW_TRACKING_FETCHING',
    DATA_NEW_TRACKING_FILL_REQUESTED: 'DATA_NEW_TRACKING_FILL_REQUESTED',
    DATA_NEW_TRACKING_FILL_SKIPPED: 'DATA_NEW_TRACKING_FILL_SKIPPED',
    DATA_NEW_TRACKING_LISTS_KEYWORDS_REQUESTED: 'DATA_NEW_TRACKING_LISTS_KEYWORDS_REQUESTED',
    DATA_NEW_TRACKING_RECEIVED: 'DATA_NEW_TRACKING_RECEIVED',
    DATA_NEW_TRACKING_REQUESTED: 'DATA_NEW_TRACKING_REQUESTED',
    DATA_NEW_TRACKING_SKIPPED: 'DATA_NEW_TRACKING_SKIPPED',

    // Panels
    DATA_LISTS_PANELS_ADD_KEYWORDS_KEYWORDS_REQUESTED: 'DATA_LISTS_PANELS_ADD_KEYWORDS_KEYWORDS_REQUESTED',

    // Trackings data
    DATA_TRACKINGS_DELETE_CANCELLED: 'DATA_TRACKINGS_DELETE_CANCELLED',
    DATA_TRACKINGS_DELETE_ERROR: 'DATA_TRACKINGS_DELETE_ERROR',
    DATA_TRACKINGS_DELETE_FETCHING: 'DATA_TRACKINGS_DELETE_FETCHING',
    DATA_TRACKINGS_DELETE_RECEIVED: 'DATA_TRACKINGS_DELETE_RECEIVED',
    DATA_TRACKINGS_DELETE_REQUESTED: 'DATA_TRACKINGS_DELETE_REQUESTED',
    DATA_TRACKINGS_RESTORE_CANCELLED: 'DATA_TRACKINGS_RESTORE_CANCELLED',
    DATA_TRACKINGS_RESTORE_ERROR: 'DATA_TRACKINGS_RESTORE_ERROR',
    DATA_TRACKINGS_RESTORE_FETCHING: 'DATA_TRACKINGS_RESTORE_FETCHING',
    DATA_TRACKINGS_RESTORE_RECEIVED: 'DATA_TRACKINGS_RESTORE_RECEIVED',
    DATA_TRACKINGS_RESTORE_REQUESTED: 'DATA_TRACKINGS_RESTORE_REQUESTED',
    DATA_TRACKINGS_ERROR: 'DATA_TRACKINGS_ERROR',
    DATA_TRACKINGS_FETCHING: 'DATA_TRACKINGS_FETCHING',
    DATA_TRACKINGS_RECEIVED: 'DATA_TRACKINGS_RECEIVED',
    DATA_TRACKINGS_REQUESTED: 'DATA_TRACKINGS_REQUESTED',
    DATA_TRACKINGS_SKIPPED: 'DATA_TRACKINGS_SKIPPED',

    // Suggested keywords data
    DATA_SUGGESTED_KWS_RESULTS_EMPTY: 'DATA_SUGGESTED_KWS_RESULTS_EMPTY',
    DATA_SUGGESTED_KWS_RESULTS_ERROR: 'DATA_SUGGESTED_KWS_RESULTS_ERROR',
    DATA_SUGGESTED_KWS_RESULTS_FETCHING: 'DATA_SUGGESTED_KWS_RESULTS_FETCHING',
    DATA_SUGGESTED_KWS_RESULTS_RECEIVED: 'DATA_SUGGESTED_KWS_RESULTS_RECEIVED',
    DATA_SUGGESTED_KWS_RESULTS_REQUESTED: 'DATA_SUGGESTED_KWS_RESULTS_REQUESTED',
    DATA_SUGGESTED_KWS_RESULTS_SKIPPED: 'DATA_SUGGESTED_KWS_RESULTS_SKIPPED',
    DATA_SUGGESTED_KWS_COUNT_EMPTY: 'DATA_SUGGESTED_KWS_COUNT_EMPTY',
    DATA_SUGGESTED_KWS_COUNT_ERROR: 'DATA_SUGGESTED_KWS_COUNT_ERROR',
    DATA_SUGGESTED_KWS_COUNT_FETCHING: 'DATA_SUGGESTED_KWS_COUNT_FETCHING',
    DATA_SUGGESTED_KWS_COUNT_RECEIVED: 'DATA_SUGGESTED_KWS_COUNT_RECEIVED',
    DATA_SUGGESTED_KWS_COUNT_REQUESTED: 'DATA_SUGGESTED_KWS_COUNT_REQUESTED',
    DATA_SUGGESTED_KWS_COUNT_SKIPPED: 'DATA_SUGGESTED_KWS_COUNT_SKIPPED',

    // SERP snapshot
    DATA_SNAPSHOT_IMAGE_REQUESTED: 'DATA_SNAPSHOT_IMAGE_REQUESTED',
    DATA_SNAPSHOT_IMAGE_FINISHED: 'DATA_SNAPSHOT_IMAGE_FINISHED',
    DATA_SNAPSHOT_IMAGE_ERROR: 'DATA_SNAPSHOT_IMAGE_ERROR',

    // User data
    DATA_USER_CHECK_STATUS_ERROR: 'DATA_USER_CHECK_STATUS_ERROR',
    DATA_USER_CHECK_STATUS_SKIPPED: 'DATA_USER_CHECK_STATUS_SKIPPED',
    DATA_USER_DATA_ERROR: 'DATA_USER_DATA_ERROR',
    DATA_USER_DATA_FETCHING: 'DATA_USER_DATA_FETCHING',
    DATA_USER_DATA_RECEIVED: 'DATA_USER_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_ERROR: 'DATA_USER_LIMIT_DATA_ERROR',
    DATA_USER_LIMIT_DATA_FETCHING: 'DATA_USER_LIMIT_DATA_FETCHING',
    DATA_USER_LIMIT_DATA_RECEIVED: 'DATA_USER_LIMIT_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_REQUESTED: 'DATA_USER_LIMIT_DATA_REQUESTED',
    DATA_USER_LIMIT_DATA_SKIPPED: 'DATA_USER_LIMIT_DATA_SKIPPED',
    DATA_USER_LOGOUT_ERROR: 'DATA_USER_LOGOUT_ERROR',
    DATA_USER_LOGOUT_RECEIVED: 'DATA_USER_LOGOUT_RECEIVED',
    DATA_USER_LOGOUT_REQUESTED: 'DATA_USER_LOGOUT_REQUESTED',
    DATA_USER_AUTH_TOKENS_RECEIVED: 'DATA_USER_AUTH_TOKENS_RECEIVED',
    DATA_USER_DATA_SKIPPED: 'DATA_USER_DATA_SKIPPED',
    DATA_USER_SETTINGS_UPDATE_REQUESTED: 'DATA_USER_SETTINGS_UPDATE_REQUESTED',
    DATA_USER_DATA_SETTINGS_UPDATE_RECEIVED: 'DATA_USER_DATA_SETTINGS_UPDATE_RECEIVED',
    DATA_USER_SETTINGS_UPDATE_ERROR: 'DATA_USER_SETTINGS_UPDATE_ERROR',
    DATA_USER_SETTINGS_UPDATE_FETCHING: 'DATA_USER_SETTINGS_UPDATE_FETCHING',
    DATA_USER_TOGGLE_WHITELABEL_REQUESTED: 'DATA_USER_TOGGLE_WHITELABEL_REQUESTED',
    DATA_USER_TOGGLE_WHITELABEL_RECEIVED: 'DATA_USER_TOGGLE_WHITELABEL_RECEIVED',
    DATA_USER_TOGGLE_WHITELABEL_ERROR: 'DATA_USER_TOGGLE_WHITELABEL_ERROR',
    DATA_USER_TOGGLE_WHITELABEL_FETCHING: 'DATA_USER_TOGGLE_WHITELABEL_FETCHING',
    DATA_USER_TOGGLE_WHITELABEL_ERROR_CLEAR: 'DATA_USER_TOGGLE_WHITELABEL_ERROR_CLEAR',
    DATA_USER_SET_UNLEASH_SESSION: 'DATA_USER_SET_UNLEASH_SESSION',
    DATA_USER_CUSTOMIZE_SETTINGS_UPDATE_REQUESTED: 'DATA_USER_DATA_SETTINGS_UPDATE_REQUESTED',
    DATA_USER_CUSTOMIZE_SETTINGS_UPDATE_RECEIVED: 'DATA_USER_DATA_SETTINGS_UPDATE_RECEIVED',
    DATA_USER_CUSTOMIZE_SETTINGS_UPDATE_ERROR: 'DATA_USER_SETTINGS_UPDATE_ERROR',

    // Reports
    DATA_DELETE_REPORT_CANCELLED: 'DATA_DELETE_REPORT_CANCELLED',
    DATA_DELETE_REPORT_FETCHING: 'DATA_DELETE_REPORT_FETCHING',
    DATA_DELETE_REPORT_RECEIVED: 'DATA_DELETE_REPORT_RECEIVED',
    DATA_DELETE_REPORT_REQUESTED: 'DATA_DELETE_REPORT_REQUESTED',
    DATA_NEW_REPORT_ERROR: 'DATA_NEW_REPORT_ERROR',
    DATA_NEW_REPORT_FETCHING: 'DATA_NEW_REPORT_FETCHING',
    DATA_NEW_REPORT_RECEIVED: 'DATA_NEW_REPORT_RECEIVED',
    DATA_NEW_REPORT_REQUESTED: 'DATA_NEW_REPORT_REQUESTED',
    DATA_REPORTS_ERROR: 'DATA_REPORTS_ERROR',
    DATA_REPORTS_FETCHING: 'DATA_REPORTS_FETCHING',
    DATA_REPORTS_RECEIVED: 'DATA_REPORTS_RECEIVED',
    DATA_REPORTS_REQUESTED: 'DATA_REPORTS_REQUESTED',
    DATA_UPDATE_REPORT_ERROR: 'DATA_UPDATE_REPORT_ERROR',
    DATA_UPDATE_REPORT_FETCHING: 'DATA_UPDATE_REPORT_FETCHING',
    DATA_UPDATE_REPORT_RECEIVED: 'DATA_UPDATE_REPORT_RECEIVED',
    DATA_UPDATE_REPORT_REQUESTED: 'DATA_UPDATE_REPORT_REQUESTED',

    // Announcement message data
    DATA_ANNOUNCEMENTS_ERROR: 'DATA_ANNOUNCEMENTS_ERROR',
    DATA_ANNOUNCEMENTS_FETCHING: 'DATA_ANNOUNCEMENTS_FETCHING',
    DATA_ANNOUNCEMENTS_RECEIVED: 'DATA_ANNOUNCEMENTS_RECEIVED',

    // ========================================================================
    // UI ACTIONS
    // ========================================================================

    UI_ALL_CLOSE: 'UI_ALL_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_CLOSE: 'UI_MESSAGES_ACCESS_DENIED_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_SHOW: 'UI_MESSAGES_ACCESS_DENIED_SHOW',
    UI_MESSAGES_ADDED_KEYWORDS_CLOSE: 'UI_MESSAGES_ADDED_KEYWORDS_CLOSE',
    UI_MESSAGES_ADDED_KEYWORDS_SHOW: 'UI_MESSAGES_ADDED_KEYWORDS_SHOW',
    UI_MESSAGES_ADD_TO_LIST_CLOSE: 'UI_MESSAGES_ADD_TO_LIST_CLOSE',
    UI_MESSAGES_ADD_TO_LIST_SHOW: 'UI_MESSAGES_ADD_TO_LIST_SHOW',
    UI_MESSAGES_ANNOTATIONS_CLOSE: 'UI_MESSAGES_ANNOTATIONS_CLOSE',
    UI_MESSAGES_ANNOTATIONS_SHOW: 'UI_MESSAGES_ANNOTATIONS_SHOW',
    UI_MESSAGES_LOOKER_STUDIO_CLOSE: 'UI_MESSAGES_LOOKER_STUDIO_CLOSE',
    UI_MESSAGES_LOOKER_STUDIO_SHOW: 'UI_MESSAGES_LOOKER_STUDIO_SHOW',
    UI_MESSAGES_CREATE_TRACKING_CLOSE: 'UI_MESSAGES_CREATE_TRACKING_CLOSE',
    UI_MESSAGES_CREATE_TRACKING_SHOW: 'UI_MESSAGES_CREATE_TRACKING_SHOW',
    UI_MESSAGES_UPSELL_CLOSE: 'UI_MESSAGES_UPSELL_CLOSE',
    UI_MESSAGES_UPSELL_SHOW: 'UI_MESSAGES_UPSELL_SHOW',
    UI_MESSAGES_DELETE_CONFIRMATION_CLOSE: 'UI_MESSAGES_DELETE_CONFIRMATION_CLOSE',
    UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM: 'UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM',
    UI_MESSAGES_DELETE_CONFIRMATION_SHOW: 'UI_MESSAGES_DELETE_CONFIRMATION_SHOW',
    UI_MESSAGES_FAILURE_CLOSE: 'UI_MESSAGES_FAILURE_CLOSE',
    UI_MESSAGES_FAILURE_SHOW: 'UI_MESSAGES_FAILURE_SHOW',
    UI_MESSAGES_LOGGED_OUT_CLOSE: 'UI_MESSAGES_LOGGED_OUT_CLOSE',
    UI_MESSAGES_LOGGED_OUT_SHOW: 'UI_MESSAGES_LOGGED_OUT_SHOW',
    UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE: 'UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE',
    UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW: 'UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW',
    UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: 'UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE',
    UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: 'UI_MESSAGES_NEED_TO_SIGN_IN_SHOW',
    UI_MESSAGES_NEW_TRACKING_INFO_CLOSE: 'UI_MESSAGES_NEW_TRACKING_INFO_CLOSE',
    UI_MESSAGES_NEW_TRACKING_INFO_SHOW: 'UI_MESSAGES_NEW_TRACKING_INFO_SHOW',
    UI_MESSAGES_NO_CONNECTION_CLOSE: 'UI_MESSAGES_NO_CONNECTION_CLOSE',
    UI_MESSAGES_NO_CONNECTION_SHOW: 'UI_MESSAGES_NO_CONNECTION_SHOW',
    UI_MESSAGES_PRICING_CLOSE: 'UI_MESSAGES_PRICING_CLOSE',
    UI_MESSAGES_PRICING_SHOW: 'UI_MESSAGES_PRICING_SHOW',
    UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: 'UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE',
    UI_MESSAGES_TRACKING_ALREADY_EXISTS_CLOSE: 'UI_MESSAGES_TRACKING_ALREADY_EXISTS_CLOSE',
    UI_MESSAGES_TRACKING_ALREADY_EXISTS_SHOW: 'UI_MESSAGES_TRACKING_ALREADY_EXISTS_SHOW',
    UI_MESSAGES_UNAUTHORIZED_CLOSE: 'UI_MESSAGES_UNAUTHORIZED_CLOSE',
    UI_MESSAGES_UNAUTHORIZED_SHOW: 'UI_MESSAGES_UNAUTHORIZED_SHOW',
    UI_MESSAGES_SHORTCUTS_SHOW: 'UI_MESSAGES_SHORTCUTS_SHOW',
    UI_MESSAGES_SHORTCUTS_CLOSE: 'UI_MESSAGES_SHORTCUTS_CLOSE',
    UI_MESSAGES_INVALID_SHARE_TOKEN_SHOW: 'UI_MESSAGES_INVALID_SHARE_TOKEN_SHOW',
    UI_MESSAGES_INVALID_SHARE_TOKEN_CLOSE: 'UI_MESSAGES_INVALID_SHARE_TOKEN_CLOSE',
    UI_MESSAGES_EDIT_TRACKING_DOMAIN_SHOW: 'UI_MESSAGES_EDIT_TRACKING_DOMAIN_SHOW',
    UI_MESSAGES_EDIT_TRACKING_DOMAIN_CLOSE: 'UI_MESSAGES_EDIT_TRACKING_DOMAIN_CLOSE',
    UI_MESSAGES_IMPORT_KWF_LIST_SHOW: 'UI_MESSAGES_IMPORT_KWF_LIST_SHOW',
    UI_MESSAGES_IMPORT_KWF_LIST_CLOSE: 'UI_MESSAGES_IMPORT_KWF_LIST_CLOSE',
    UI_MISC_ANALYTICS_CONFIGURED_SET: 'UI_MISC_ANALYTICS_CONFIGURED_SET',
    UI_MISC_COLOR_SCHEME_SET: 'UI_MISC_COLOR_SCHEME_SET',
    UI_MISC_NAVIGATED_INTERNALLY_SET: 'UI_MISC_NAVIGATED_INTERNALLY_SET',
    UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET: 'UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET',
    UI_MISC_NOTIFICATIONS_CLOSE_ALL: 'UI_MISC_NOTIFICATIONS_CLOSE_ALL',
    UI_MISC_NOTIFICATIONS_ERROR_SHOW: 'UI_MISC_NOTIFICATIONS_ERROR_SHOW',
    UI_MISC_NOTIFICATIONS_INFO_SHOW: 'UI_MISC_NOTIFICATIONS_INFO_SHOW',
    UI_MISC_ONLINE_STATUS_SET: 'UI_MISC_ONLINE_STATUS_SET',
    UI_MISC_OPEN_TRACKING_AND_FILL_KWS_PANEL: 'UI_MISC_OPEN_TRACKING_AND_FILL_KWS_PANEL',
    UI_MISC_SCROLL_RESULT_LIST_KW_ID_SET: 'UI_MISC_SCROLL_RESULT_LIST_KW_ID_SET',

    UI_SETTINGS_IS_WITH_DELETED_TRACKINGS: 'UI_SETTINGS_IS_WITH_DELETED_TRACKINGS',
    UI_SETTINGS_IS_WITH_DELETED_KEYWORDS: 'UI_SETTINGS_IS_WITH_DELETED_KEYWORDS',

    UI_SETTINGS_IS_WITH_GROUPED_BY_DOMAIN: 'UI_SETTINGS_IS_WITH_GROUPED_BY_DOMAIN',

    // Dropdowns
    UI_DROPDOWNS_APP_CLOSE: 'UI_DROPDOWNS_APP_CLOSE',
    UI_DROPDOWNS_APP_SHOW: 'UI_DROPDOWNS_APP_SHOW',
    UI_DROPDOWNS_EXPORT_CLOSE: 'UI_DROPDOWNS_EXPORT_CLOSE',
    UI_DROPDOWNS_EXPORT_SHOW: 'UI_DROPDOWNS_EXPORT_SHOW',
    UI_DROPDOWNS_HELP_CLOSE: 'UI_DROPDOWNS_HELP_CLOSE',
    UI_DROPDOWNS_HELP_SHOW: 'UI_DROPDOWNS_HELP_SHOW',
    UI_DROPDOWNS_IMPORT_LIST_CLOSE: 'UI_DROPDOWNS_IMPORT_LIST_CLOSE',
    UI_DROPDOWNS_IMPORT_LIST_SHOW: 'UI_DROPDOWNS_IMPORT_LIST_SHOW',
    UI_DROPDOWNS_MANAGE_TAGS_COLOR_CLOSE: 'UI_DROPDOWNS_MANAGE_TAGS_COLOR_CLOSE',
    UI_DROPDOWNS_MANAGE_TAGS_COLOR_SHOW: 'UI_DROPDOWNS_MANAGE_TAGS_COLOR_SHOW',
    UI_DROPDOWNS_TAGS_BULK_CLOSE: 'UI_DROPDOWNS_TAGS_BULK_CLOSE',
    UI_DROPDOWNS_TAGS_ADDKW_SHOW: 'UI_DROPDOWNS_TAGS_ADDKW_SHOW',
    UI_DROPDOWNS_TAGS_ADDKW_CLOSE: 'UI_DROPDOWNS_TAGS_ADDKW_CLOSE',
    UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAG_SET: 'UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAG_SET',
    UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAGS_RESET: 'UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAGS_RESET',
    UI_DROPDOWNS_TAGS_BULK_SELECTED_TAGS_RESET: 'UI_DROPDOWNS_TAGS_BULK_SELECTED_TAGS_RESET',
    UI_DROPDOWNS_TAGS_BULK_SELECTED_TAG_SET: 'UI_DROPDOWNS_TAGS_BULK_SELECTED_TAG_SET',
    UI_DROPDOWNS_TAGS_BULK_SHOW: 'UI_DROPDOWNS_TAGS_BULK_SHOW',
    UI_DROPDOWNS_TAGS_CLOSE: 'UI_DROPDOWNS_TAGS_CLOSE',
    UI_DROPDOWNS_TAGS_SELECTED_TAGS_RESET: 'UI_DROPDOWNS_TAGS_SELECTED_TAGS_RESET',
    UI_DROPDOWNS_TAGS_SELECTED_TAG_SET: 'UI_DROPDOWNS_TAGS_SELECTED_TAG_SET',
    UI_DROPDOWNS_TAGS_SHOW: 'UI_DROPDOWNS_TAGS_SHOW',
    UI_DROPDOWNS_TIMEFRAME_CLOSE: 'UI_DROPDOWNS_TIMEFRAME_CLOSE',
    UI_DROPDOWNS_TIMEFRAME_SHOW: 'UI_DROPDOWNS_TIMEFRAME_SHOW',
    UI_DROPDOWNS_TRACKING_ACTIONS_CLOSE: 'UI_DROPDOWNS_TRACKING_ACTIONS_CLOSE',
    UI_DROPDOWNS_TRACKING_ACTIONS_SHOW: 'UI_DROPDOWNS_TRACKING_ACTIONS_SHOW',
    UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_CLOSE: 'UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_CLOSE',
    UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_SHOW: 'UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_SHOW',
    UI_DROPDOWNS_TRACKINGS_ACTIONS_SHOW: 'UI_DROPDOWNS_TRACKINGS_ACTIONS_SHOW',
    UI_DROPDOWNS_TRACKINGS_ACTIONS_CLOSE: 'UI_DROPDOWNS_TRACKINGS_ACTIONS_CLOSE',
    UI_DROPDOWNS_TRACKING_LIST_CLOSE: 'UI_DROPDOWNS_TRACKING_LIST_CLOSE',
    UI_DROPDOWNS_TRACKING_LIST_SEARCH_SET: 'UI_DROPDOWNS_TRACKING_LIST_SEARCH_SET',
    UI_DROPDOWNS_TRACKING_LIST_SHOW: 'UI_DROPDOWNS_TRACKING_LIST_SHOW',
    UI_DROPDOWNS_USER_CLOSE: 'UI_DROPDOWNS_USER_CLOSE',
    UI_DROPDOWNS_USER_SHOW: 'UI_DROPDOWNS_USER_SHOW',
    UI_DROPDOWNS_TAGS_FILTER_CLOSE: 'UI_DROPDOWNS_TAGS_FILTER_CLOSE',
    UI_DROPDOWNS_TAGS_FILTER_SHOW: 'UI_DROPDOWNS_TAGS_FILTER_SHOW',

    UI_DROPDOWNS_LOCATIONS_LIST_CLOSE: 'UI_DROPDOWNS_LOCATIONS_LIST_CLOSE',
    UI_DROPDOWNS_LOCATIONS_LIST_SEARCH_SET: 'UI_DROPDOWNS_LOCATIONS_LIST_SEARCH_SET',
    UI_DROPDOWNS_LOCAITONS_LIST_SHOW: 'UI_DROPDOWNS_LOCAITONS_LIST_SHOW',

    // New tracking
    UI_NEW_TRACKING_DOMAIN_SET: 'UI_NEW_TRACKING_DOMAIN_SET',
    UI_NEW_TRACKING_KEYWORDS_ADD_REQUESTED: 'UI_NEW_TRACKING_KEYWORDS_ADD_REQUESTED',
    UI_NEW_TRACKING_KEYWORDS_FILTERED_COUNT_SET: 'UI_NEW_TRACKING_KEYWORDS_FILTERED_COUNT_SET',
    UI_NEW_TRACKING_KEYWORDS_RESET: 'UI_NEW_TRACKING_KEYWORDS_RESET',
    UI_NEW_TRACKING_KEYWORDS_SET: 'UI_NEW_TRACKING_KEYWORDS_SET',
    UI_NEW_TRACKING_KEYWORDS_SET_REQUESTED: 'UI_NEW_TRACKING_KEYWORDS_SET_REQUESTED',
    UI_NEW_TRACKING_ALL_SUGGESTED_KEYWORDS_SET_REQUESTED: 'UI_NEW_TRACKING_ALL_SUGGESTED_KEYWORDS_SET_REQUESTED',
    UI_NEW_TRACKING_ALL_GSC_KEYWORDS_SET_REQUESTED: 'UI_NEW_TRACKING_ALL_GSC_KEYWORDS_SET_REQUESTED',
    UI_NEW_TRACKING_RESET: 'UI_NEW_TRACKING_RESET',
    UI_NEW_TRACKING_SELECTED_LISTS_RESET: 'UI_NEW_TRACKING_SELECTED_LISTS_RESET',
    UI_NEW_TRACKING_SELECTED_LIST_SET: 'UI_NEW_TRACKING_SELECTED_LIST_SET',
    UI_NEW_TRACKING_TEMPLATE_FILL: 'UI_NEW_TRACKING_TEMPLATE_FILL',
    UI_NEW_TRACKING_FILTER_SETTINGS_SET: 'UI_NEW_TRACKING_FILTER_SETTINGS_SET',
    UI_NEW_TRACKING_CONFIG_DATA_SET: 'UI_NEW_TRACKING_CONFIG_DATA_SET',
    UI_NEW_TRACKING_CONFIG_DATA_RESET: 'UI_NEW_TRACKING_CONFIG_DATA_RESET',
    UI_NEW_TRACKING_SELECTED_FILTER_SETTINGS_SET: 'UI_NEW_TRACKING_SELECTED_FILTER_SETTINGS_SET',
    UI_NEW_TRACKING_FILTER_SETTINGS_UPDATE: 'UI_NEW_TRACKING_FILTER_SETTINGS_UPDATE',
    UI_NEW_TRACKING_SELECTED_FILTER_GSC_SETTINGS_SET: 'UI_NEW_TRACKING_SELECTED_FILTER_GSC_SETTINGS_SET',
    UI_NEW_TRACKING_SELECTED_FILTER_GSC_SETTINGS_UPDATE: 'UI_NEW_TRACKING_SELECTED_FILTER_GSC_SETTINGS_UPDATE',
    UI_NEW_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET: 'UI_NEW_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET',
    UI_NEW_TRACKING_QUICK_FILTER_GSC_SETTINGS_SEARCH_SET: 'UI_NEW_TRACKING_QUICK_FILTER_GSC_SETTINGS_SEARCH_SET',

    // Tracking
    UI_TRACKING_ANNOTATION_DELETING_ID_SET: 'UI_TRACKING_ANNOTATION_DELETING_ID_SET',
    UI_TRACKING_ANNOTATION_UPDATING_ID_SET: 'UI_TRACKING_ANNOTATION_UPDATING_ID_SET',
    UI_TRACKING_KEYWORDS_SELECT: 'UI_TRACKING_KEYWORDS_SELECT',
    UI_TRACKING_KEYWORDS_SELECT_ALL: 'UI_TRACKING_KEYWORDS_SELECT_ALL',
    UI_TRACKING_KEYWORDS_SELECT_ALL_REQUESTED: 'UI_TRACKING_KEYWORDS_SELECT_ALL_REQUESTED',
    UI_TRACKING_KEYWORDS_SET: 'UI_TRACKING_KEYWORDS_SET',
    UI_TRACKING_KEYWORDS_UNSELECT: 'UI_TRACKING_KEYWORDS_UNSELECT',
    UI_TRACKING_KEYWORDS_UNSELECT_ALL: 'UI_TRACKING_KEYWORDS_UNSELECT_ALL',
    UI_TRACKING_KEYWORD_DETAIL_CLOSE: 'UI_TRACKING_KEYWORD_DETAIL_CLOSE',
    UI_TRACKING_KEYWORD_DETAIL_RESET: 'UI_TRACKING_KEYWORD_DETAIL_RESET',
    UI_TRACKING_KEYWORD_DETAIL_SHOW: 'UI_TRACKING_KEYWORD_DETAIL_SHOW',
    UI_TRACKING_RESET: 'UI_TRACKING_RESET',
    UI_TRACKING_TIMEFRAME_SET: 'UI_TRACKING_TIMEFRAME_SET',
    UI_TRACKING_REPORT_ACTIVE_SET: 'UI_TRACKING_REPORT_ACTIVE_SET',

    // Tracking filter
    UI_TRACKING_FILTER_ACTIVE_SET: 'UI_TRACKING_FILTER_ACTIVE_SET',
    UI_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET: 'UI_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET',
    UI_TRACKING_FILTER_RESET: 'UI_TRACKING_FILTER_RESET',
    UI_TRACKING_FILTER_SETTINGS_SET: 'UI_TRACKING_FILTER_SETTINGS_SET',
    UI_TRACKING_FILTER_VISIBILITY_TOGGLE: 'UI_TRACKING_FILTER_VISIBILITY_TOGGLE',
    UI_TRACKING_QUICK_FILTER_SETTINGS_TAG_IDS_SET: 'UI_TRACKING_QUICK_FILTER_SETTINGS_TAG_IDS_SET',
    UI_TRACKING_QUICK_FILTER_TAGS_UPDATE: 'UI_TRACKING_QUICK_FILTER_TAGS_UPDATE',

    // UI DEFAULTS ACTIONS
    UI_DEFAULTS_LOCATION_SET: 'UI_DEFAULTS_LOCATION_SET',
    UI_DEFAULTS_PLATFORM_SET: 'UI_DEFAULTS_PLATFORM_SET',
    UI_DEFAULTS_SORTING_SETTINGS_SET: 'UI_DEFAULTS_SORTING_SETTINGS_SET',
    UI_DEFAULTS_TRACKING_GROUP_ORDERED_KEYS_SET: 'UI_DEFAULTS_TRACKING_GROUP_ORDERED_KEYS_SET',

    // Modals
    UI_MODALS_SERP_MODAL_CLOSE: 'UI_MODALS_SERP_MODAL_CLOSE',
    UI_MODALS_SERP_MODAL_SHOW: 'UI_MODALS_SERP_MODAL_SHOW',
    UI_MODALS_SHARE_MODAL_CLOSE: 'UI_MODALS_SHARE_MODAL_CLOSE',
    UI_MODALS_SHARE_MODAL_SHOW: 'UI_MODALS_SHARE_MODAL_SHOW',
    UI_MODALS_CREATED_TRACKING_TIPS_MODAL_CLOSE: 'UI_MODALS_CREATED_TRACKING_TIPS_MODAL_CLOSE',
    UI_MODALS_CREATED_TRACKING_TIPS_MODAL_SHOW: 'UI_MODALS_CREATED_TRACKING_TIPS_MODAL_SHOW',

    // Panels
    UI_PANELS_ADD_KEYWORDS_CLOSE: 'UI_PANELS_ADD_KEYWORDS_CLOSE',
    UI_PANELS_ADD_KEYWORDS_FILTERED_COUNT_SET: 'UI_PANELS_ADD_KEYWORDS_FILTERED_COUNT_SET',
    UI_PANELS_ADD_KEYWORDS_KEYWORDS_ADD_REQUESTED: 'UI_PANELS_ADD_KEYWORDS_KEYWORDS_ADD_REQUESTED',
    UI_PANELS_ADD_KEYWORDS_KEYWORDS_RESET: 'UI_PANELS_ADD_KEYWORDS_KEYWORDS_RESET',
    UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET: 'UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET',
    UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET_REQUESTED: 'UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET_REQUESTED',
    UI_PANELS_ADD_KEYWORDS_SELECTED_LISTS_RESET: 'UI_PANELS_ADD_KEYWORDS_SELECTED_LISTS_RESET',
    UI_PANELS_ADD_KEYWORDS_SELECTED_LIST_SET: 'UI_PANELS_ADD_KEYWORDS_SELECTED_LIST_SET',
    UI_PANELS_ALL_GSC_KEYWORDS_SET_REQUESTED: 'UI_PANELS_ALL_GSC_KEYWORDS_SET_REQUESTED',
    UI_PANELS_ADD_KEYWORDS_SHOW: 'UI_PANELS_ADD_KEYWORDS_SHOW',
    UI_PANELS_APP_CLOSE: 'UI_PANELS_APP_CLOSE',
    UI_PANELS_APP_SHOW: 'UI_PANELS_APP_SHOW',
    UI_PANELS_MANAGE_TAGS_CLOSE: 'UI_PANELS_MANAGE_TAGS_CLOSE',
    UI_PANELS_MANAGE_TAGS_SHOW: 'UI_PANELS_MANAGE_TAGS_SHOW',
    UI_PANELS_REPORTS_CLOSE: 'UI_PANELS_REPORTS_CLOSE',
    UI_PANELS_REPORTS_CURRENT_REPORT_ID_SET: 'UI_PANELS_REPORTS_CURRENT_REPORT_ID_SET',
    UI_PANELS_REPORTS_EDIT_ALERT_TRIGGERS_SELECTED_SET: 'UI_PANELS_REPORTS_EDIT_ALERT_TRIGGERS_SELECTED_SET',
    UI_PANELS_REPORTS_EDIT_EMAILS_SET: 'UI_PANELS_REPORTS_EDIT_EMAILS_SET',
    UI_PANELS_REPORTS_EDIT_NAME_SET: 'UI_PANELS_REPORTS_EDIT_NAME_SET',
    UI_PANELS_REPORTS_EDIT_REQUESTED: 'UI_PANELS_REPORTS_EDIT_REQUESTED',
    UI_PANELS_REPORTS_EDIT_TYPE_SET: 'UI_PANELS_REPORTS_EDIT_TYPE_SET',
    UI_PANELS_REPORTS_NEW_ALERT_TRIGGERS_SELECTED_SET: 'UI_PANELS_REPORTS_NEW_ALERT_TRIGGERS_SELECTED_SET',
    UI_PANELS_REPORTS_NEW_EMAILS_SET: 'UI_PANELS_REPORTS_NEW_EMAILS_SET',
    UI_PANELS_REPORTS_NEW_NAME_SET: 'UI_PANELS_REPORTS_NEW_NAME_SET',
    UI_PANELS_REPORTS_NEW_TYPE_SET: 'UI_PANELS_REPORTS_NEW_TYPE_SET',
    UI_PANELS_REPORTS_SHOW: 'UI_PANELS_REPORTS_SHOW',
    UI_PANELS_SET_ADDKW_TAGS: 'UI_PANELS_SET_ADDKW_TAGS',
    UI_PANELS_UNASSIGN_ADDKW_TAG: 'UI_PANELS_UNASSIGN_ADDKW_TAG',
    UI_PANELS_CUSTOMIZE_REPORTS_SHOW: 'UI_PANELS_CUSTOMIZE_REPORTS_SHOW',
    UI_PANELS_CUSTOMIZE_REPORTS_CLOSE_REQUESTED: 'UI_PANELS_CUSTOMIZE_REPORTS_CLOSE_REQUESTED',
    UI_PANELS_CUSTOMIZE_REPORTS_CLOSE: 'UI_PANELS_CUSTOMIZE_REPORTS_CLOSE',
    UI_PANELS_CUSTOMIZE_REPORTS_DIRTY_SET: 'UI_PANELS_CUSTOMIZE_REPORTS_DIRTY_SET',
    UI_PANELS_CUSTOMIZE_RESULTS_SHOW: 'UI_PANELS_CUSTOMIZE_RESULTS_SHOW',
    UI_PANELS_CUSTOMIZE_RESULTS_CLOSE: 'UI_PANELS_CUSTOMIZE_RESULTS_CLOSE',

    // Tracking list
    UI_TRACKING_LIST_FILTER_SET: 'UI_TRACKING_LIST_FILTER_SET',
    UI_TRACKING_LIST_OPENED_CARD_SET: 'UI_TRACKING_LIST_OPENED_CARD_SET',

    UI_GMB_DATA_ERROR: 'UI_GMB_DATA_ERROR',

    // ========================================================================
    // OTHER ACTIONS
    // ========================================================================

    ANALYTICS_TRACK: 'ANALYTICS_TRACK',

    ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
    ROUTER_NAVIGATION_BACK_REQUESTED: 'ROUTER_NAVIGATION_BACK_REQUESTED',
    ROUTER_NAVIGATION_REPLACE_REQUESTED: 'ROUTER_NAVIGATION_REPLACE_REQUESTED',
    ROUTER_NAVIGATION_REQUESTED: 'ROUTER_NAVIGATION_REQUESTED',
    ROUTER_AUTH_TOKENS_HIDE_REQUESTED: 'ROUTER_AUTH_TOKENS_HIDE_REQUESTED',
};

export default ActionTypes;
