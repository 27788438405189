import { eventChannel } from 'redux-saga';
import { fork, call, spawn, put, race, select, take, takeEvery } from 'redux-saga/effects';
import Alert from 'react-s-alert';
import concat from 'ramda/src/concat';
import includes from 'ramda/src/includes';
import trim from 'ramda/src/trim';
import uniq from 'ramda/src/uniq';
import isNil from 'ramda/src/isNil';
import slice from 'ramda/src/slice';
import DOMService from 'mangools-commons/lib/services/DOMService';
import ColorSchemes from 'mangools-commons/lib/constants/ColorSchemes';

import {
    receivedErrorNotificationAction,
    receivedInfoNotificationAction,
    requestedAddKeywordsPanelKeywordsSet,
    requestedCloseAllNotificationsAction,
    setAddKeywordsPanelFilteredCount,
    setAddKeywordsPanelKeywords,
    setNewTrackingKeywords,
    setNewTrackingKeywordsFilteredCount,
    setOnlineStatus,
    setSelectedTrackingKeywords,
    showAddKeywordsPanel,
    setColorScheme,
    setTrackingQuickFilterTagIds,
    closeCustomizeReportPanel,
} from 'actions/uiActions';
import { gtmTrack } from 'actions/analyticsActions';

import { requestedNavigationAction } from 'actions/routerActions';
import Values from 'constants/Values';

import {
    filteredAndSortedTrackingKeywordsSelector,
    trackingDetailKeywordsSelector,
    assignedTrackingDetailKeywordTagsMapSelector,
    allSuggestedKeywordStringsSelector,
    filteredSuggestedKeywordStringsSelector,
    allGscKeywordStringsSelector,
    filteredGscKeywordStringsSelector,
} from 'selectors/dataSelectors';

import {
    addKeywordsPanelKeywordsSelector,
    onlineStatusSelector,
    currentColorSchemeSelector,
    customizeReportIsDirtySelector,
    customizeReporVisibilitySelector,
    newTrackingLastDateInHistorySelector,
    addKeywordsPanelAssignedLastDateInHistorySelector,
} from 'selectors/uiSelectors';

import { remainingTrackedKeywordLimitSelector } from 'selectors/userSelectors';

import { trackingQuickFilterTagIdsSelector } from 'selectors/commonSelectors';

import RoutePaths from 'constants/RoutePaths';
import ActionTypes from 'constants/ActionTypes';
import { MAX_KEYWORD_LENGTH } from 'constants/Other';
import { analyticsEvents } from 'constants/analytics';
import { newTrackingKeywordsSelector } from 'selectors/sharedSelectors';
import { normalizedStr } from 'services/KeywordUtils';
import { isEmpty, reject, uniqBy } from 'ramda';
import { convertKwToObj } from 'utils/keywordImport';

// EXPORTS

export function* showErrorNotification(message, config = {}) {
    yield put(receivedErrorNotificationAction(message, config));
    yield call(Alert.error, message, config);
}

export function* showInfoNotification(message, config = {}) {
    yield put(receivedInfoNotificationAction(message, config));
    yield call(Alert.info, message, config);
}

export function* closeAllNotifications() {
    yield put(requestedCloseAllNotificationsAction());
    yield call(Alert.closeAll);
}

// HELPERS

function* showImportedKeywordsInfoMessage(payload) {
    const { keywords, listCount } = payload;
    let message;

    if (!isNil(listCount) && listCount > 1) {
        message = `Successfully imported <strong>${listCount}</strong> list${listCount > 1 ? 's' : ''}.`;
    } else {
        message = `Successfully imported <strong>${keywords.length}</strong> keyword${keywords.length > 1 ? 's' : ''}.`;
    }

    yield fork(showInfoNotification, message, { html: true });
}

// WORKERS

function* selectAllTrackingKeywords() {
    const keywords = yield select(filteredAndSortedTrackingKeywordsSelector);
    const keywordsIds = keywords.map(({ id }) => id);
    yield put(setSelectedTrackingKeywords(keywordsIds));
}

function* addNewKeywords(action) {
    const { keywords } = action.payload;
    const lastDateInHistoryFromRedux = yield select(newTrackingLastDateInHistorySelector);
    const lastDateInHistory = action.payload.lastDateInHistory || lastDateInHistoryFromRedux;
    const remainingTrackedKeywordLimit = yield select(remainingTrackedKeywordLimitSelector);

    // Mapujeme objekty, abychom upravili keyword
    const newKeywords = keywords.map(kwObj => ({
        ...kwObj, // Zachováme celou strukturu objektu
        keyword: trim(kwObj.keyword), // Ořízneme pouze keyword
    }));
    const newKeywordsCount = newKeywords.length;

    // Filtrování příliš krátkých klíčových slov
    const newNotShortKeywords = newKeywords.filter(kwObj => kwObj.keyword.length >= Values.KEYWORD_MIN_CHARS);
    const newNotShortKeywordsCount = newNotShortKeywords.length;

    // Filter existing in area
    const currentAreaKeywords = yield select(newTrackingKeywordsSelector);
    const currentAreaKeywordsCount = currentAreaKeywords.length;

    // Spojení stávajících a nových klíčových slov a odstranění duplicit podle `keyword`
    const newUniqAreaKeywords = uniqBy(kwObj => kwObj.keyword, concat(currentAreaKeywords, newNotShortKeywords));
    const newUniqAreaKeywordsCount = newUniqAreaKeywords.length;

    const tooShortKwsCount = newKeywordsCount - newNotShortKeywordsCount;
    const filteredInAreaCount = newKeywordsCount + currentAreaKeywordsCount - newUniqAreaKeywordsCount;

    const keywordsInLimit = slice(0, remainingTrackedKeywordLimit, newUniqAreaKeywords);
    const keywordsNotInLimit = slice(remainingTrackedKeywordLimit, Infinity, newUniqAreaKeywords);

    // Set filtered counts and kws
    yield put(setNewTrackingKeywordsFilteredCount({ duplicates: filteredInAreaCount, tooShort: tooShortKwsCount }));
    yield put(setNewTrackingKeywords({ keywordsInLimit, keywordsNotInLimit, lastDateInHistory }));
    yield fork(showImportedKeywordsInfoMessage, action.payload);
}

function* setNewKeywords(action) {
    const remainingTrackedKeywordLimit = yield select(remainingTrackedKeywordLimitSelector);
    const payloadKeywords = action.payload.newKeywords;
    const isKwfImport = !!action.payload.isKwfImport;
    const lastDateInHistoryFromRedux = yield select(newTrackingLastDateInHistorySelector);

    const lastDateInHistory = action.payload.lastDateInHistory || lastDateInHistoryFromRedux;

    // Mapa objektů na stringy a aplikuj formátování
    const newKeywords = payloadKeywords.map(kwObj => {
        const kw = kwObj.keyword; // Přístup k klíči `keyword`
        const filteredWhiteChars = kw.replace(/\s/g, ' ');
        const sliced = slice(0, MAX_KEYWORD_LENGTH, filteredWhiteChars);
        const trimmed = trim(sliced);

        return {
            ...kwObj, // Zachováme celou strukturu, včetně `history`
            keyword: trimmed, // Upravíme pouze `keyword`
        };
    });

    const newKeywordsCount = newKeywords.length;
    const newNotShortKeywords = newKeywords.filter(kwObj => kwObj.keyword.length >= Values.KEYWORD_MIN_CHARS);
    const newNotShortKeywordsCount = newNotShortKeywords.length;

    // Filter existing in area
    const newUniqAreaKeywords = uniqBy(kwObj => kwObj.keyword, newNotShortKeywords); // Unikátní podle `keyword`
    const newUniqAreaKeywordsCount = newUniqAreaKeywords.length;

    const tooShortKwsCount = newKeywordsCount - newNotShortKeywordsCount;
    const filteredInAreaCount = newNotShortKeywordsCount - newUniqAreaKeywordsCount;

    const keywordsInLimit = slice(0, remainingTrackedKeywordLimit, newUniqAreaKeywords);
    const keywordsNotInLimit = slice(remainingTrackedKeywordLimit, Infinity, newUniqAreaKeywords);

    if (isKwfImport) {
        yield fork(showImportedKeywordsInfoMessage, { keywords: payloadKeywords, listCount: 1 });
    }

    // Set filtered count and kws
    yield put(setNewTrackingKeywordsFilteredCount({ duplicates: filteredInAreaCount, tooShort: tooShortKwsCount }));
    yield put(setNewTrackingKeywords({ keywordsInLimit, keywordsNotInLimit, lastDateInHistory }));
}

function* addKeywords(action) {
    const { keywords, isWithVisualResponse = true } = action.payload;
    const remainingTrackedKeywordLimit = yield select(remainingTrackedKeywordLimitSelector);
    const lastDateInHistoryFromRedux = yield select(addKeywordsPanelAssignedLastDateInHistorySelector);
    const lastDateInHistory = action.payload.lastDateInHistory || lastDateInHistoryFromRedux;

    const newKeywords = keywords.map(kw => {
        const filteredWhiteChars = kw.keyword.replace(/\s/g, ' ');
        const sliced = slice(0, MAX_KEYWORD_LENGTH, filteredWhiteChars);
        const trimmed = trim(sliced);

        return {
            ...kw, // Zachováme původní strukturu objektu, pokud existuje
            keyword: trimmed, // Formátovaný `keyword`
        };
    });

    const newKeywordsCount = newKeywords.length;
    const newNotShortKeywords = newKeywords.filter(kw => kw.keyword.length >= Values.KEYWORD_MIN_CHARS);
    const newNotShortKeywordsCount = newNotShortKeywords.length;

    // Filter existing in area
    const currentAreaKeywords = yield select(addKeywordsPanelKeywordsSelector);
    const currentAreaKeywordsCount = currentAreaKeywords.length;

    const newUniqAreaKeywords = uniq(concat(currentAreaKeywords, newNotShortKeywords));
    const newUniqAreaKeywordsCount = newUniqAreaKeywords.length;

    const tooShortKwsCount = newKeywordsCount - newNotShortKeywordsCount;
    const filteredInAreaCount = newKeywordsCount + currentAreaKeywordsCount - newUniqAreaKeywordsCount;

    // Filter existing in tracking
    const trackingKeywordObjects = yield select(trackingDetailKeywordsSelector);
    const trackingKeywords = trackingKeywordObjects.map(kw => kw.keyword);
    const keywordsInTracking = newUniqAreaKeywords.filter(kw => includes(kw.keyword, trackingKeywords));

    const keywordsNotInTracking = newUniqAreaKeywords.filter(kw => !includes(kw.keyword, trackingKeywords));
    const keywordsNotInTrackingInLimit = slice(0, remainingTrackedKeywordLimit, keywordsNotInTracking);
    const keywordsNotInTrackingNotInLimit = slice(remainingTrackedKeywordLimit, Infinity, keywordsNotInTracking);

    // Set filtered counts and kws
    if (isWithVisualResponse){
        yield put(setAddKeywordsPanelFilteredCount({ duplicates: filteredInAreaCount, tooShort: tooShortKwsCount }));
    }
    yield put(
        setAddKeywordsPanelKeywords({
            keywordsInLimit: keywordsNotInTrackingInLimit,
            keywordsInTracking,
            keywordsNotInLimit: keywordsNotInTrackingNotInLimit,
            lastDateInHistory,
        }),
    );

    if (isWithVisualResponse){
        yield fork(showImportedKeywordsInfoMessage, action.payload);
    }
}

function* setKeywords(action) {
    const remainingTrackedKeywordLimit = yield select(remainingTrackedKeywordLimitSelector);
    const payloadKeywords = action.payload.newKeywords;
    const isKwfImport = !!action.payload.isKwfImport;
    const lastDateInHistoryFromRedux = yield select(addKeywordsPanelAssignedLastDateInHistorySelector);
    const lastDateInHistory = action.payload.lastDateInHistory || lastDateInHistoryFromRedux;

    // Mapa na objekty s formátovaným klíčem
    const newKeywords = payloadKeywords.map(kw => {
        const keyword = typeof kw === 'string' ? kw : kw.keyword;
        const filteredWhiteChars = keyword.replace(/\s/g, ' ');
        const sliced = slice(0, MAX_KEYWORD_LENGTH, filteredWhiteChars);
        const trimmed = trim(sliced);

        return {
            ...kw, // Zachováme původní strukturu objektu, pokud existuje
            keyword: trimmed, // Formátovaný `keyword`
        };
    });

    // Filtrace prázdných klíčových slov
    const filteredEmptyKeywords = newKeywords.filter(kwObj => kwObj.keyword && kwObj.keyword.trim() !== '');

    const newKeywordsCount = filteredEmptyKeywords.length;
    const newNotShortKeywords = filteredEmptyKeywords.filter(kwObj => kwObj.keyword.length >= Values.KEYWORD_MIN_CHARS);
    const newNotShortKeywordsCount = newNotShortKeywords.length;

    // Unikátní klíčová slova v rámci oblasti
    const newUniqAreaKeywords = uniqBy(kwObj => kwObj.keyword, newNotShortKeywords);
    const newUniqAreaKeywordsCount = newUniqAreaKeywords.length;

    // Počet příliš krátkých a duplicitních klíčových slov
    const tooShortKwsCount = newKeywordsCount - newNotShortKeywordsCount;
    const filteredInAreaCount = newNotShortKeywordsCount - newUniqAreaKeywordsCount;

    // Filter existing in tracking
    const trackingKeywordObjects = yield select(trackingDetailKeywordsSelector);
    const trackingKeywords = trackingKeywordObjects.map(kw => kw.keyword);

    const keywordsInTracking = newUniqAreaKeywords.filter(kwObj => includes(kwObj.keyword, trackingKeywords));
    const keywordsNotInTracking = newUniqAreaKeywords.filter(kwObj => !includes(kwObj.keyword, trackingKeywords));

    const keywordsNotInTrackingInLimit = slice(0, remainingTrackedKeywordLimit, keywordsNotInTracking);
    const keywordsNotInTrackingNotInLimit = slice(remainingTrackedKeywordLimit, Infinity, keywordsNotInTracking);

    // Set filtered count and kws
    if (isKwfImport) {
        yield fork(showImportedKeywordsInfoMessage, { keywords: payloadKeywords, listCount: 1 });
    }

    yield put(setAddKeywordsPanelFilteredCount({ duplicates: filteredInAreaCount, tooShort: tooShortKwsCount }));
    yield put(
        setAddKeywordsPanelKeywords({
            keywordsInLimit: keywordsNotInTrackingInLimit,
            keywordsInTracking,
            keywordsNotInLimit: keywordsNotInTrackingNotInLimit,
            lastDateInHistory,
        }),
    );
}

function* useAllSuggestedKeywords(action) {
    const allSuggestedKws = yield select(allSuggestedKeywordStringsSelector);
    const filteredKws = yield select(filteredSuggestedKeywordStringsSelector);
    const tagAreaKws = yield select(newTrackingKeywordsSelector);

    const { toSelected, keyword } = action.payload;
    const normalizedKw = normalizedStr(keyword);
    const kwInObj = convertKwToObj(normalizedKw, 'suggested');

    if (normalizedKw) {
        if (toSelected) {
            // Přidání nového klíčového slova, pokud již není přítomno
            const newKeywords = tagAreaKws.some(kw => kw.keyword === normalizedKw)
                ? tagAreaKws
                : [...tagAreaKws, kwInObj];
            yield call(setNewKeywords, { payload: { newKeywords } });
        } else {
            // Odebrání klíčového slova
            const newKeywords = tagAreaKws.filter(kw => kw.keyword !== normalizedKw);
            yield call(setNewKeywords, { payload: { newKeywords } });
        }
    } else if (toSelected) {
        // Přidání všech filtrovaných klíčových slov, která již nejsou v tagAreaKws
        const newKeywords = [
            ...tagAreaKws,
            ...filteredKws
                .map(kw => convertKwToObj(kw, 'suggested'))
                .filter(kw => !tagAreaKws.some(tagKw => tagKw.keyword === kw.keyword)),
        ];
        yield call(setNewKeywords, { payload: { newKeywords } });
    } else {
        // Odebrání klíčových slov, která se nacházejí v allSuggestedKws
        const newKeywords = tagAreaKws.filter(kw => !allSuggestedKws.includes(normalizedStr(kw.keyword)));
        yield call(setNewKeywords, { payload: { newKeywords } });
    }
}

function* useAllGscKeywords(action) {
    const allGscKws = yield select(allGscKeywordStringsSelector);
    const filteredKws = yield select(filteredGscKeywordStringsSelector);
    const tagAreaKws = yield select(newTrackingKeywordsSelector);

    const { toSelected, keyword } = action.payload;
    const normalizedKw = normalizedStr(keyword);
    const kwInObj = convertKwToObj(normalizedKw, 'gsc');

    if (normalizedKw) {
        if (toSelected) {
            // Přidání nového klíčového slova, pokud již není přítomno
            const newKeywords = tagAreaKws.some(kw => kw.keyword === normalizedKw)
                ? tagAreaKws
                : [...tagAreaKws, kwInObj];
            yield call(setNewKeywords, { payload: { newKeywords } });
        } else {
            // Odebrání klíčového slova
            const newKeywords = tagAreaKws.filter(kw => kw.keyword !== normalizedKw);
            yield call(setNewKeywords, { payload: { newKeywords } });
        }
    } else if (toSelected) {
        // Přidání všech filtrovaných klíčových slov, která již nejsou v tagAreaKws
        const newKeywords = [
            ...tagAreaKws,
            ...filteredKws
                .map(kw => convertKwToObj(kw, 'gsc'))
                .filter(kw => !tagAreaKws.some(tagKw => tagKw.keyword === kw.keyword)),
        ];
        yield call(setNewKeywords, { payload: { newKeywords } });
    } else {
        // Odebrání klíčových slov, která se nacházejí v allSuggestedKws
        const newKeywords = tagAreaKws.filter(kw => !allGscKws.includes(normalizedStr(kw.keyword)));
        yield call(setNewKeywords, { payload: { newKeywords } });
    }
}

function* useAllGscKeywordsInPanel(action) {
    const allGscKws = yield select(allGscKeywordStringsSelector);
    const filteredKws = yield select(filteredGscKeywordStringsSelector);
    const tagAreaKws = yield select(addKeywordsPanelKeywordsSelector);

    const { toSelected, keyword } = action.payload;
    const normalizedKw = normalizedStr(keyword);
    const kwInObj = convertKwToObj(normalizedKw, 'gsc');

    if (normalizedKw) {
        if (toSelected) {
            // Přidání nového klíčového slova, pokud již není přítomno
            const newKeywords = tagAreaKws.some(kw => kw.keyword === normalizedKw)
                ? tagAreaKws
                : [...tagAreaKws, kwInObj];
            yield call(addKeywords, { payload: { keywords: newKeywords, isWithVisualResponse: false } });
        } else {
            // Odebrání klíčového slova
            const newKeywords = tagAreaKws.filter(kw => kw.keyword !== normalizedKw);
            yield call(addKeywords, { payload: { keywords: newKeywords, isWithVisualResponse: false } });
        }
    } else if (toSelected) {
        // Přidání všech filtrovaných klíčových slov, která již nejsou v tagAreaKws
        const newKeywords = [
            ...tagAreaKws,
            ...filteredKws
                .map(kw => convertKwToObj(kw, 'gsc'))
                .filter(kw => !tagAreaKws.some(tagKw => tagKw.keyword === kw.keyword)),
        ];
        yield call(addKeywords, { payload: { keywords: newKeywords, isWithVisualResponse: false } });
    } else {
        // Odebrání klíčových slov, která se nacházejí v allGscKws
        const newKeywords = tagAreaKws.filter(kw => !allGscKws.includes(normalizedStr(kw.keyword)));
        yield call(addKeywords, { payload: { keywords: newKeywords, isWithVisualResponse: false } });
    }
}

function* navigateToTrackingAndFillAddKeywordsPanel(action) {
    const { trackingId, keywords } = action.payload;

    // Navigate to tracking
    yield put(requestedNavigationAction(RoutePaths.TRACKING, { id: trackingId }));

    // Wait to load tracking
    const { successFetch, _errorFetch } = yield race({
        successFetch: take(ActionTypes.DATA_TRACKING_DETAIL_RECEIVED),
        _errorFetch: take(ActionTypes.DATA_TRACKING_DETAIL_ERROR),
    });

    // If all OK, open add kws panel and fill with keywords
    if (!isNil(successFetch)) {
        yield put(showAddKeywordsPanel());
        yield put(requestedAddKeywordsPanelKeywordsSet({ newKeywords: keywords }));
    }
}

function* updateOnlineStatus() {
    const currentStatus = yield select(onlineStatusSelector);
    const newStatus = window.navigator.onLine;
    yield put(setOnlineStatus({ onlineStatus: newStatus }));

    if (currentStatus === true && newStatus === false) {
        yield call(closeAllNotifications);
        // NOTE: Went from online to offline, show error notification
        yield call(showErrorNotification, 'You have lost your <strong>internet connection</strong>!', {
            html: true,
            timeout: 'none',
        });
    } else if (currentStatus === false && newStatus === true) {
        yield call(closeAllNotifications);
        // NOTE: Went from offline to online, show success notification
        yield call(showInfoNotification, 'You are now <strong>online</strong>!', {
            html: true,
        });
    }
}

function* updateColorSchemeFromChannel(channel) {
    const colorScheme = yield select(currentColorSchemeSelector);

    if (colorScheme === ColorSchemes.AUTO) {
        if (DOMService.isDarkMode(channel)) {
            DOMService.darkModeOn();
        } else {
            DOMService.darkModeOff();
        }

        // NOTE: this triggers force rerender in components to check for dark mode
        yield put(setColorScheme(ColorSchemes.LIGHT));
        yield put(setColorScheme(ColorSchemes.AUTO));
    }
}

function* updateColorScheme({ payload }) {
    if (DOMService.isDarkMode(payload)) {
        DOMService.darkModeOn();
    } else {
        DOMService.darkModeOff();
    }

    yield put(
        gtmTrack({
            action: payload,
            event: analyticsEvents.THEME_TOGGLE,
        }),
    );
}

function* updateTagFilter() {
    const tagsMap = yield select(assignedTrackingDetailKeywordTagsMapSelector);
    let filteringTagIds = yield select(trackingQuickFilterTagIdsSelector);

    filteringTagIds = filteringTagIds.filter(tagId => !isNil(tagsMap[tagId]));

    yield put(setTrackingQuickFilterTagIds(filteringTagIds));
}

function* handleCloseCustomizeReportPanel() {
    const customizeReportPanelVisible = yield select(customizeReporVisibilitySelector);
    const customizeReportIsDirty = yield select(customizeReportIsDirtySelector);

    if (customizeReportPanelVisible && customizeReportIsDirty) {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm('You have unsaved changes. Are you sure you want to continue?');

        if (confirm) {
            yield put(closeCustomizeReportPanel());
        }
    } else {
        yield put(closeCustomizeReportPanel());
    }
}

// CHANNELS

function onlineStatusChannels() {
    return eventChannel(emitter => {
        window.addEventListener('online', emitter);
        window.addEventListener('offline', emitter);

        return () => {
            window.removeEventListener('online', emitter);
            window.removeEventListener('offline', emitter);
        };
    });
}

function preferredColorSchemeChannel() {
    return eventChannel(emitter => {
        const mql = DOMService.registerMqlEventListener(colorScheme => emitter(colorScheme));
        return () => DOMService.removeMqlEventListener(mql);
    });
}

// WATCHERS

function* watchTrackinKeywordsSelectAllRequests() {
    yield takeEvery(ActionTypes.UI_TRACKING_KEYWORDS_SELECT_ALL_REQUESTED, selectAllTrackingKeywords);
}

function* watchAddKeywordsPanelKeywordsAddRequests() {
    yield takeEvery(ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_ADD_REQUESTED, addKeywords);
}

function* watchAddKeywordsPanelKeywordsSetRequests() {
    yield takeEvery(ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET_REQUESTED, setKeywords);
}

function* watchNewTrackingKeywordsAddRequests() {
    yield takeEvery(ActionTypes.UI_NEW_TRACKING_KEYWORDS_ADD_REQUESTED, addNewKeywords);
}

function* watchNewTrackingKeywordsSetRequests() {
    yield takeEvery(ActionTypes.UI_NEW_TRACKING_KEYWORDS_SET_REQUESTED, setNewKeywords);
}

function* watchNewTrackingSuggestedKeyworsSetRequests() {
    yield takeEvery(ActionTypes.UI_NEW_TRACKING_ALL_SUGGESTED_KEYWORDS_SET_REQUESTED, useAllSuggestedKeywords);
}

function* watchNewTrackingGscKeywordsSetRequests() {
    yield takeEvery(ActionTypes.UI_NEW_TRACKING_ALL_GSC_KEYWORDS_SET_REQUESTED, useAllGscKeywords);
}

function* watchPanelGscKeywordsSetRequests() {
    yield takeEvery(ActionTypes.UI_PANELS_ALL_GSC_KEYWORDS_SET_REQUESTED, useAllGscKeywordsInPanel);
}

function* watchOpenTrackingAndFillKwsPanelRequests() {
    yield takeEvery(ActionTypes.UI_MISC_OPEN_TRACKING_AND_FILL_KWS_PANEL, navigateToTrackingAndFillAddKeywordsPanel);
}

function* watchUpdateTagFilterRequests() {
    yield takeEvery(ActionTypes.UI_TRACKING_QUICK_FILTER_TAGS_UPDATE, updateTagFilter);
}

function* watchOnlineStatusByEvents() {
    const channel = yield call(onlineStatusChannels);
    yield takeEvery(channel, updateOnlineStatus);
}

function* watchPreferredColorSchemeByEvents() {
    const channel = yield call(preferredColorSchemeChannel);
    yield takeEvery(channel, updateColorSchemeFromChannel);
}

function* watchPreferredColorSchemeChange() {
    yield takeEvery(ActionTypes.UI_MISC_COLOR_SCHEME_SET, updateColorScheme);
}
function* watchCustomizeReportPanelCloseRequests() {
    yield takeEvery(
        [ActionTypes.UI_ALL_CLOSE, ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_CLOSE_REQUESTED],
        handleCloseCustomizeReportPanel,
    );
}

export function* watchUIRequests() {
    yield spawn(watchOnlineStatusByEvents);
    yield spawn(watchNewTrackingSuggestedKeyworsSetRequests);
    yield spawn(watchTrackinKeywordsSelectAllRequests);
    yield spawn(watchAddKeywordsPanelKeywordsAddRequests);
    yield spawn(watchAddKeywordsPanelKeywordsSetRequests);
    yield spawn(watchNewTrackingKeywordsAddRequests);
    yield spawn(watchNewTrackingKeywordsSetRequests);
    yield spawn(watchOpenTrackingAndFillKwsPanelRequests);
    yield spawn(watchPreferredColorSchemeByEvents);
    yield spawn(watchPreferredColorSchemeChange);
    yield spawn(watchUpdateTagFilterRequests);
    yield spawn(watchCustomizeReportPanelCloseRequests);
    yield spawn(watchNewTrackingGscKeywordsSetRequests);
    yield spawn(watchPanelGscKeywordsSetRequests);
}
